import './index.css';
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from 'recoil';

import { Header } from './components/header';
import { Footer } from './components/footer';
import { Pages } from './pages';
import { SBComponents } from '@sb/web-components';

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <SBComponents />
        <div className="bg-white mx-auto flex flex-col min-h-screen">
          <Header />
          <div className="flex grow flex-col">
            <Pages />
          </div>
          <Footer />
        </div>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
