export const Member = (props: { timeStart: string; timeEnd: string; name: string; contribution: string; link: string; index: number }) => {
    const {timeStart, timeEnd, name, contribution, link, index} = props;
    const timeSpan = timeEnd? `${timeStart} - ${timeEnd}` : timeStart;
    const bgColor = index%2 === 0 ? 'bg-yellow-50' : 'bg-green-50';
    return (<>
            <div className={'col-span-3 text-xl font-bold border p-2 flex items-center mb-1 ' + bgColor}>
                {link? 
                    (<a className='text-blue-500' href={link}><strong>{name}</strong></a>) 
                    : (<strong>{name}</strong>)
                }                
            </div>
            <div className={'col-span-7 text-sm border p-2 ml-1 mb-1 ' + bgColor}>{contribution}</div>
            <div className={'col-span-2 border p-2 ml-1 mb-1 flex justify-center ' + bgColor}>
                <strong><small>{timeSpan}</small></strong>
            </div>
        </>);
};