import { StaticBlock } from "../../components/content/static-block";
import { FAQ } from "./faq";
import { US } from "./us";

export const About = () => (<>
    <div className="flex justify-center">
        <div className='flex flex-col container m-4'>
            
            <StaticBlock title='Introduction'>
            Ibn-e-Hayyan Society of Advance Sciences (IHSAS) is a club of volunteers of both students and 
            scholars to advance the society in science and technology.
            </StaticBlock>
            
            <StaticBlock title='Mission Statement'>
            Make the society a better place by getting rid of poverty and ignorance by using science and technology.
            </StaticBlock>

            <StaticBlock title='North Star'>
            Everyone is rich and thoughtful at doctorate level.
            </StaticBlock>

            <StaticBlock title='How it works'>
            Volunteers works on the projects in the milestone leading towards the north-star. The projects makes money. 
            The volunteers are paid according to their contributions.
            </StaticBlock>

            <FAQ />

            <US />
        </div>
    </div>
</>);