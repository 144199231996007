const policy = <div className='terms'>
<h1>Terms of Service</h1>
Please read these terms of service (“terms”, “terms of service”) carefully before using this website (the “service”).

<h2>Conditions of Use</h2>
We will provide their services to you, which are subject to the conditions stated below in this document. Every time you visit this website or use its services, you accept the following conditions. This is why we urge you to read them carefully.

<h2>Communications</h2>
The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the news on our website, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements, and other communications we provide to you electronically meet the legal requirements that such communications be in writing.

<h2>Applicable Law</h2>
By visiting this website, you agree that the laws of your location, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between this website and you, or its business partners and associates.

<h2>Disputes</h2>
Any dispute related in any way to your visit to this website shall be arbitrated by state or federal court of your location and you consent to exclusive jurisdiction and venue of such courts.

<h2>Comments, Reviews, and Emails</h2>
Visitors may post content as long as it is not obscene, illegal, defamatory, threatening, infringing of intellectual property rights, invasive of privacy, or injurious in any other way to third parties. Content has to be free of software viruses, political campaigns, and commercial solicitation.
We reserve all rights (but not the obligation) to remove and/or edit such content. When you post your content, you grant non-exclusive, royalty-free and irrevocable right to use, reproduce, publish, modify such content throughout the world in any media.

<h2>License and Site Access</h2>
We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it. This may be done only with written consent from us.

<h2>User Account</h2>
If you are an owner of an account on this website, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password.

We reserve all rights to terminate accounts, edit or remove content and cancel orders at their sole discretion.
Please read these terms of service (“terms”, “terms of service”) carefully before using this website website (the “service”).

</div>;

export const Terms = () => policy;