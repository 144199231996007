import { NavLink } from "react-router-dom";
import bism from '../assets/bism.png';
import { Navigation } from './navigation';

export const Header = () => (<>
    <div className="continer grid place-items-center mix-w-full">
        <img className="w-52" src={bism} alt='بسم الله' />
    </div>
    <hr />
    <div className="w-full pl-10 pr-2">
        {/* top title and menu */}
        <div className="flex flex-row flex-wrap justify-start w-full">
            <div className="flex-none text-3xl font-light px-4">
                <NavLink style={ ({isActive}) => (
                    isActive 
                    ? { fontWeight: 'bold', pointerEvents: 'none' } : {})} to='/' key='index'>IHSAS</NavLink>
            </div>
            <Navigation />
        </div>
    </div>
    <hr />
</>);