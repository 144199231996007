import banner from '../assets/banner.png';

export const Landing = () => (<>
    <div className="container mx-auto">
        {/* quote */}
        <div className="md:flex md:flex-row py-8">
            <div className="text-7xl font-thin px-4">
                IBN-E-HAYYAN SOCIETY OF ADVANCE SCIENCES
            </div>
            <div className="flex text-3xl font-thin px-4 items-center">
                The first essential in chemistry is that you should perform practical work and conduct experiments, 
                for he who performs not practical work nor makes experiments will never attain to the least degree of mastery. 
                But you, O my son, do experiment&apos;s so that you may acquire knowledge. &ndash; Jabir Bin Hayyan
            </div>
        </div>
    </div>
        
        {/* image */}
    <div className="w-full">
        <img className="object-fill w-full" src={banner} alt='banner' />
    </div>
        
    <div className="container mx-auto">
        {/* Ayah */}
        <div className="flex px-12 py-4 text-5xl font-thin justify-evenly mx-20 my-20 tracking-wide leading-loose">
            And your God is one God! there is no god but He; He is the Beneficent, the Merciful. Most surely in the creation of the 
            heavens and the earth and the alternation of the night and the day, and the ships that run in the sea with that which 
            profits men, and the water that Allah sends down from the cloud, then gives life with it to the earth after its death and 
            spreads in it all (kinds of) animals, and the changing of the winds and the clouds made subservient between the heaven and 
            the earth, there are signs for a people who understand. [2:163-164]
        </div>
    </div>
</>);