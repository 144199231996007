import { About } from "../pages/about";
import { Login } from "../pages/login";

import { Dashboard } from "../pages/club/dashboard";

export const menu = [
    {
        path: 'about',
        element: <About />,
        title: 'ABOUT',
        link: '/about'
    },
];

export const loginMenu = [
    {
        path: 'login',
        element: <Login />,
        title: 'LOGIN',
        link: '/login'
    },
];

export const clubMenu = [

    {
        path: 'login',
        element: <Dashboard />,
        title: 'DASHBOARD',
        link: '/login'
    },

];