import { Routes, Route } from "react-router-dom";

import { useUserProfile } from '@sb/web-modules';

import { Landing } from './landing';
import { NoPage } from "./nopage";
import { menu, loginMenu, clubMenu } from '../data/menu';
import { Privacy } from "./privacy";
import { Terms } from "./terms";

export const Pages = () => {
    const profile = useUserProfile();

    const allMenu: any[] = [ ...menu,
        ...('email' in profile? clubMenu : loginMenu),
    ]

    return (<>
    <Routes>
        <Route path="/">
            <Route index element={<Landing />} />
            {
                allMenu.map((item, i) => (<Route path={item.path} element={item.element} key={i} />))
            }
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<NoPage />} />
        </Route>
    </Routes>
    </>)
};