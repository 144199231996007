import { StaticBlock } from "../../components/content/static-block";
import { Member } from './member';
import { members } from './members-data';

export const US = () => {
    return (<StaticBlock title="Notable Members">
        Some of our alumni 
            <div className='mt-4 grid grid-cols-12'>
                <div className='col-span-3 text-2xl font-bold flex justify-center'>Name</div>
                <div className='col-span-7 text-2xl font-bold flex justify-center'>Contributions</div>
                <div className='col-span-2 text-2xl font-bold flex justify-center'>Era</div>
                {members.map((m, i) => (
                    <Member {...m} index={i} key={i} />
                ))}                
            </div>
        </StaticBlock>);
};