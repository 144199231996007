export const Footer = () => (<>
    <footer className="bg-black flex flex-col bottom-0">    
        <div className="px-14 py-8 text-white font-medium text-2xl pt-20">
            I H S A S
        </div>
        <div className="px-14 text-white leading-10 text-2xl pb-8 mb-8">
            &copy; ibn-e-Hayyan Society of Advance Sciences
        </div>
        &nbsp;
    </footer>
</>);