import { QnA } from "./qna";

export const FAQ = () => {
    return (
    <div className='border shadow rounded p-4 mt-8 flex flex-col items-center content-center'>
        
        <h2 className='text-3xl font-bold m-8'>Frequently asked questions</h2>
        <dl className='space-y-4 text-xl pb-8 mb-8'>

            <QnA title='Are you a charity organisation?'>
                No, its a volunteering not-for-profit platform for individuals to work on the projects and 
                get paid as per their contribution and the money made by the project.
            </QnA>
            <QnA title='Are you donating food and cloths to the poors?'>
                There can be projects like that on this platform, but the main idea is to build the capabilities, so that 
                by using advanced
                science and technology, such as Artificial Intelligence and robotics, growing crops and preparing food and 
                cloths for the people, without human intervention, so that ultimately there won't be any cost involved in 
                manufacturing and production and everyone can get neccessities at first and luxuries later, for free.
            </QnA>
            <QnA title='Why not become a freelancer instead?'>
                When you are freelancing, you are paid for the time, but the owner earns great amount of money out of your 
                work. 
                Volunteering on this platform, will make you earn money till the time the project you worked-on, is
                making money, that is, you will have the share in the project as per your contribution.
            </QnA>
            <QnA title='Is this platform only for Muslim people?'>
                Though, the policies for the projects on this platform must adhere to the Muslim code of practices
                (e.g. lawful industries/businesses, ethics, hijab so on), but it's mission is to progress the whole society 
                in general, irrespective of cast, creed or believes.
            </QnA>
            <QnA title="What will people do, if they don't have to work to pay for their neccessities and luxuries?">
                The other parallel goal of this platform is to educate people by giving free, easy and interesting 
                opportunities to educate themselves, so that they can take creative part in improving the society,
                instead of living their lives for earning their bread and butter and other basic needs.
            </QnA>
            <QnA title="How can I contribute?">
                There is no limit on how anyone can contribute, you can pitch the idea, manage the project or execute the 
                tasks to complete the project. Your contribution can be theoritical or practical. 
            </QnA>
            <QnA title="How my contribution will be paid off?">
                For your contribution in a project you will be given ihsas-coin(s) for that project, based on the complexity 
                and efforts. 
                Ihsas-coins will be your share in the project. Once, the project you made contribution, makes money, 
                the money will be divided by the total ihsas-coins earned for that project, and each one will be given 
                the amount of money, as much ihsas-coins they have. 
                Assume, in a month, a project makes $100, and the project took 20 ihsas-coins to complete, by 4 people, 
                each ihsas-coin will worth $5, if one of the person have 5 coins, 
                he will receive $25, if another have 10 ihsas-coins, will receive $50, so on.
            </QnA>
            <QnA title="What if my contribution is used in multiple projects?">
                You will receive additional ihsas-coin(s), for all those projects, using your contributed work. 
                The platform is created such that people involved in theoretical work can share similar benefits as 
                the people doing practical work. The projects, must be created on top of other projects 
                (theoretical and/or practical). The projects, on which, other projects are based-on, will receive ihsas-coins
                for other projects. For example, project-B (physical project) is based on project-A (theoretical project),
                then project-A will receive a ihsas-coin of project-B, so it will receive the money made by project-B, as per
                the ihsas-coin it has. So, project-A will receive ihsas-coin for all the projects based on it. 
                (PS. project-A can be theoretical as well as practical project).
            </QnA>
            <QnA title="What is difference between volunteering here and working on opensource?">
                Software development and IT services are one of an important part of this platform. In opensource software
                development, developer makes very little as compared to the money earned by companies, i.e. big companies are at most
                advantage. Volunteering here will make you earn as per your contribution, as well as benefit the whole society
                in general.
            </QnA>

        </dl>

    </div>);
};