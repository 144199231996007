export const members = [
    { timeStart: '806 CE', timeEnd: '816 CE', name: 'Jabir ibn Hayyan', contribution: 'Father of Chemistry, famously known for his contributions in the field of Physics, Astronomy, Medicine, Zoology, Botany, Logic and Grammar', link: 'https://en.wikipedia.org/wiki/Jabir_ibn_Hayyan' },
    { timeStart: '9th Century', timeEnd: '', name: 'Al-Kindi', contribution: 'a philosopher and polymath who made significant contributions to mathematics, physics and medicine', link: '' },
    { timeStart: '9th Century', timeEnd: '', name: 'Al-Battani', contribution: 'an astronomer and mathematician who made significant contributions to trigonometry and astronomy.', link: '' },
    { timeStart: '9th Century', timeEnd: '', name: 'Al-Farghani', contribution: 'an astronomer and mathematician who made significant contributions to astronomy and geography.', link: '' },
    { timeStart: '9th Century', timeEnd: '', name: 'Al-Tabari', contribution: "a historian and scholar who wrote the famous Tarikh al-Rusul wa'l-Muluk, a comprehensive history of the world", link: '' },
    { timeStart: '9th Century', timeEnd: '', name: 'Al-Razi', contribution: 'a physician and philosopher who made significant contributions to medicine, chemistry, and philosophy.', link: '' },
    { timeStart: '9th Century', timeEnd: '', name: 'Al-Dinawari', contribution: 'a historian, botanist, and geographer who wrote the famous book on botany, the Kitab al-Nabat.', link: '' },
    { timeStart: '10th Century', timeEnd: '', name: 'Ibn Sina (Avicenna)', contribution: 'one of the most significant thinkers and writers know for his contributions to medicine, philosophy, and physics', link: '' },
    { timeStart: '10th Century', timeEnd: '', name: 'Al-Zahrawi', contribution: 'wrote a highly influential medical encyclopedia called the Al-Tasrif, which included over 200 surgical instruments that he had designed.', link: '' },
    { timeStart: '10th Century', timeEnd: '', name: 'Al-Farabi', contribution: 'a philosopher who made significant contributions to logic, metaphysics, and political philosophy', link: '' },
    { timeStart: '10th Century', timeEnd: '', name: 'Al-Biruni', contribution: 'a scientist and polymath who made significant contributions to astronomy, mathematics, and geography.', link: '' },
    { timeStart: '10th Century', timeEnd: '', name: 'Al-Masudi', contribution: 'a historian and geographer who wrote the famous Meadows of Gold, a comprehensive geographic encyclopedia and a famous book on world history, Muruj al-Dhahab.', link: '' },
    { timeStart: '10th Century', timeEnd: '', name: 'Ibn al-Jazzar', contribution: 'a physician and writer who made significant contributions to the field of medicine, and wrote the famous book, the Kitab al-Fusul fi al-Tibb.', link: '' },
    { timeStart: '11th Century', timeEnd: '', name: 'Al-Maghribi', contribution: 'a mathematician and astronomer who made significant contributions to the fields of mathematics and astronomy.', link: '' },
    { timeStart: '11th Century', timeEnd: '', name: 'Omar Khayyam', contribution: 'a mathematician, astronomer, and poet who is best known for his work on algebra and for his poetry, which is still widely read today.', link: '' },
    { timeStart: '11th Century', timeEnd: '', name: 'Ibn al-Haytham', contribution: 'a scientist who made significant contributions to optics, including the development of the first accurate theory of vision.', link: '' },
    { timeStart: '11th Century', timeEnd: '', name: 'Al-Khazrajī', contribution: 'an astronomer who wrote the famous astronomical tables, the Zij al-Khazrajī.', link: '' },
    { timeStart: '12th Century', timeEnd: '', name: 'Ibn Rushd (Averroes)', contribution: 'a philosopher and jurist who made significant contributions to the fields of logic, medicine, and philosophy', link: '' },
    { timeStart: '12th Century', timeEnd: '', name: 'Al-Khazini', contribution: 'a physicist who made significant contributions to the study of density and buoyancy.', link: '' },
    { timeStart: '12th Century', timeEnd: '', name: 'Al-Idrisi', contribution: 'a geographer and cartographer who created the famous Tabula Rogeriana, a world map that was considered the most accurate map of the world for several centuries.', link: '' },
    { timeStart: '12th Century', timeEnd: '', name: 'Ibn Zuhr', contribution: 'a physician and surgeon who made significant contributions to the fields of medicine and anatomy.', link: '' },
    { timeStart: '12th Century', timeEnd: '', name: 'Ibn Jubayr', contribution: 'a traveler and geographer who wrote the famous Rihla, a comprehensive travelogue of his journeys across the Muslim world.', link: '' },
    { timeStart: '13th Century', timeEnd: '', name: 'Nasir al-Din al-Tusi', contribution: 'an astronomer and mathematician who made significant contributions to trigonometry and developed a mathematical model of planetary motion.', link: '' },
    { timeStart: '13th Century', timeEnd: '', name: 'Ibn Arabi', contribution: 'a philosopher and theologian who made significant contributions to Islamic metaphysics and spirituality.', link: '' },
    { timeStart: '13th Century', timeEnd: '', name: 'Ibn al-Nafis', contribution: 'a physician who made significant contributions to the study of pulmonary circulation.', link: '' },
    { timeStart: '13th Century', timeEnd: '', name: 'Ibn al-Baitar', contribution: 'a botanist and pharmacist who made significant contributions to the study of plants and their medicinal properties.', link: '' },
    { timeStart: '13th Century', timeEnd: '', name: 'Ibn al-Quff', contribution: 'a physician and surgeon who made significant contributions to the fields of surgery and medicine.', link: '' },
    { timeStart: '13th Century', timeEnd: '', name: 'Al-Jazari', contribution: 'an engineer and inventor who is known for his Book of Knowledge of Ingenious Mechanical Devices, a seminal work on mechanical engineering.', link: '' },
    { timeStart: '14th Century', timeEnd: '', name: 'Ibn Khaldun', contribution: 'a historian and scholar who wrote the famous Muqaddimah, a comprehensive introduction to history and social sciences.', link: '' },
    { timeStart: '15th Century', timeEnd: '', name: 'Al-Kashi', contribution: 'a mathematician and astronomer who made significant contributions to trigonometry and developed a method for calculating the sine function that was accurate to 16 decimal places.', link: '' }
];