import { NavLink } from "react-router-dom";

import { useUserProfile, useSignOut } from '@sb/web-modules';

import { menu, loginMenu, clubMenu } from '../data/menu';

export const Navigation = () => {
    const profile = useUserProfile();
    const signOut = useSignOut();

    const allMenu: any[] = [
        ...menu,
        ...('email' in profile? clubMenu : loginMenu),
    ];
    
    return (<>
        <div className="flex-auto flex flex-wrap justify-end grow content-center">
            {'email' in profile && (
                <div className='flex mr-4 items-center'><small>
                    {(profile as any).email}
                    [<button onClick={() => signOut()} className="text-blue-500">Sign Out</button>]
                </small></div>
            )}
            <nav className='flex text-2xl font-light gap-x-4 '>
                {allMenu.map((item, i) => (<NavLink style={ ({isActive}) => (
                    isActive 
                    ? { fontWeight: 'bold', pointerEvents: 'none' } : {})} to={item.link} key={i}>{item.title}</NavLink>))}
            </nav>
        </div>
    </>);
};